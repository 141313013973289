import { Box, IconButton, makeStyles, Tab, Typography } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import CloseIcon from "@material-ui/icons/Close"
import { TabContext, TabList, TabPanel } from "@material-ui/lab"
import DrawerNotificationTag from "./utils/DrawerNotificationTag"
import { useDispatch, useSelector } from "react-redux"
import { updateSeenNotification } from "../../../redux/slices/notificationsSlice"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    fontFamily: ["DM Sans"].join(","),
  },
  titleRoot: {
    width: "97%",
    display: "flex",
    marginTop: "40px",
    marginBottom: "30px",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginLeft: "15px",
    fontFamily: ["DM Sans"].join(","),
  },
  rootForm: {
    width: "100%",
  },
  typoTitle: {
    fontFamily: "DM Sans",
    fontSize: "30px",
    fontWeight: 400,
  },
  indicator: {
    backgroundColor: "#209CA3",
    height: "10px",
    top: "45px",
    color: "red",
  },
  selected: {
    color: "#209CA3",
  },
  tabRoot: {
    fontSize: "13px",
    textTransform: "none",
    textAlign: "left",
    padding: 0,
  },
  tabPanelRoot: {
    padding: "0",
    width: "100%",
    marginTop: "20px",
  },
}))

function Notification({ close }) {
  const classes = useStyles()

  const dispatch = useDispatch()

  const [value, setValue] = useState("1")

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const clubId = useSelector(state => state.userSlice?.user?.userInfo?.clubId)

  const allNotifications = useSelector(
    state => state.notificationsSlice.allNotifications
  )

  const bookingNotifications = useSelector(
    state => state.notificationsSlice.bookingNotifications
  )

  useEffect(() => {
    dispatch(updateSeenNotification({ clubId }))
  }, [])

  return (
    <div className={classes.root}>
      <div className={classes.titleRoot}>
        <Typography className={classes.typoTitle} style={{ margin: "13px" }}>
          Notifications
        </Typography>
        <IconButton onClick={close}>
          <CloseIcon style={{ margin: "13px" }} />
        </IconButton>
      </div>
      <div className={classes.rootForm}>
        <Box
          sx={{
            width: "85%",
            typography: "body1",
            marginLeft: "35px",
            marginRight: "50px",
          }}
        >
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                classes={{
                  indicator: classes.indicator,
                  flexContainer: "flexContainer",
                }}
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab
                  label={`Bookings (${bookingNotifications.length})`}
                  className={classes.tabRoot}
                  value="1"
                  classes={{ selected: classes.selected }}
                  style={{ borderBottom: "3px solid #F0F0F0" }}
                />
                <Tab
                  label={`All (${allNotifications.length})`}
                  value="2"
                  className={classes.tabRoot}
                  style={{ borderBottom: "3px solid #F0F0F0" }}
                  classes={{ selected: classes.selected }}
                />
                <Tab
                  label="&nbsp;"
                  value="3"
                  style={{ borderBottom: "3px solid #F0F0F0" }}
                  disabled
                />
              </TabList>
            </Box>

            <TabPanel className={classes.tabPanelRoot} value="1">
              <div>
                {bookingNotifications.map((notification, index) => (
                  <DrawerNotificationTag
                    key={index}
                    userName={notification.userName}
                    courtName={notification.courtName}
                    amountPaid={notification.amountPaid}
                    currency={notification.currency}
                    date={notification.date}
                    startTime={notification.startTime}
                    endTime={notification.endTime}
                  />
                ))}
              </div>
            </TabPanel>
            <TabPanel className={classes.tabPanelRoot} value="2">
              <div>
                {allNotifications.map((notification, index) => (
                  <DrawerNotificationTag
                    key={index}
                    userName={notification.userName}
                    courtName={notification.courtName}
                    amountPaid={notification.amountPaid}
                    currency={notification.currency}
                    date={notification.date}
                    startTime={notification.startTime}
                    endTime={notification.endTime}
                  />
                ))}
              </div>
            </TabPanel>
          </TabContext>
        </Box>
      </div>
    </div>
  )
}

export default Notification
