import { Typography } from "@material-ui/core"
import React from "react"
import icon from "../../../../images/noti_icon.svg"

function DrawerNotificationTag({
  key,
  userName,
  courtName,
  date,
  amountPaid,
  currency,
  startTime,
  endTime,
}) {
  const notificationDate = new Date(date)

  const day = notificationDate.getDate()
  const month = notificationDate.toLocaleString("en-us", { month: "long" })
  const weekDay = notificationDate.toLocaleString("en-us", { weekday: "long" })

  let daySuffix
  if (day === 1) {
    daySuffix = "st"
  } else if (day === 2) {
    daySuffix = "nd"
  } else if (day === 3) {
    daySuffix = "rd"
  } else {
    daySuffix = "th"
  }

  const formattedDate = `${weekDay} ${day}${daySuffix} ${month}`
  const notificationMessage = (
    <>
      {userName} booked{" "}
      <span
        style={{
          fontSize: "12px",
          marginLeft: "0px",
          fontFamily: "DM Sans",
          fontWeight: "bold",
        }}
      >
        {courtName}
      </span>{" "}
      on {formattedDate}
    </>
  )

  const formattedAmount = (
    <>
      <span
        style={{
          fontSize: "11px",
          marginLeft: "0px",
          fontFamily: "DM Sans",
          fontWeight: "bold",
        }}
      >
        {amountPaid?.toFixed(2)}
      </span>{" "}
      {currency}
    </>
  )

  return (
    <div
      key={key}
      style={{
        width: "auto",
        padding: "4px",
        backgroundColor: "#F5F6F8",
        marginTop: "15px",
        borderRadius: 10,
        color: "#000000",
        display: "flex",
        flexDirection: "row",
        fontSize: "5px",
        alignItems: "center",
        justifyContent: "space-around",
      }}
    >
      <div style={{ width: "10%" }}>
        <img src={icon} alt="logo" width="30px" style={{ padding: "5px" }} />
      </div>
      <div style={{ width: "60%", whiteSpace: "normal" }}>
        <Typography
          style={{
            fontSize: "10px",
            marginLeft: "0px",
            fontFamily: "DM Sans",
            fontWeight: "400",
          }}
        >
          {notificationMessage}
          <br />
          {`${startTime} - ${endTime}`}
        </Typography>
      </div>
      <div
        style={{
          width: "20%",
          alignSelf: "flex-start",
          marginTop: "5px",
        }}
      >
        <Typography style={{ fontSize: "10px", fontFamily: "DM Sans" }}>
          {formattedAmount}
        </Typography>
      </div>
    </div>
  )
}

export default DrawerNotificationTag
